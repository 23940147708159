angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('public.confirmEmail', {
            url: '/confirmEmail/:token',
            views: {
                "public": {
                    controller: 'ConfirmEmailCtrl',
                    templateUrl: 'web/public/confirmEmail/confirmEmail.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Confirm Email'
            }
        });
    })
    .controller('ConfirmEmailCtrl', function($scope, session, $stateParams, $state, loginService, $translate) {
        var that = this;

        that.loading = true;

        loginService.confirmEmail($stateParams.token).then(function (response) {
            that.loading = false;
            if (response.data.token) {
                $state.go('public.login');
                swal($translate.instant("¡Correcto!"), $translate.instant("Email confirmado"), "info");
            }
        }, function (response) {
            swal($translate.instant('Algo ha ido mal'), $translate.instant("No se ha podido confirmar el email"), "error");
        });

    });
